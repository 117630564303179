import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ChangePassword } from "../models/changepassword.model";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormService } from "../core/form.service";
import { ChangepasswordService } from "../service/changepassword.service";
import { ValidationService } from "../service/validation.service";
import { UtilityService } from "../service/utility.service";
import { Constants } from "../constant/constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-recovery-password",
  templateUrl: "./recovery-password.component.html",
  styleUrls: ["./recovery-password.component.scss"],
})
export class RecoveryPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  isShowConfirmPassword = false;
  isShowNewPassword = false;
  public messageList: any = new ChangePassword();
  IsChangePassword = false;
  IsMobile = false;

  accessToken = "";
  constructor(
    private router: Router,
    private toaster: ToastrService,
    private formService: FormService,
    private changePasswordService: ChangepasswordService,
    public validationService: ValidationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("IsChangePassword")) {
        this.IsChangePassword = JSON.parse(params["IsChangePassword"]);
      }
      if (params.hasOwnProperty("AccessToken")) {
        this.accessToken = params["AccessToken"];
      }
      if (params.hasOwnProperty("IsMobile")) {
        if (params["IsMobile"].toLowerCase() =='true') {
          this.IsMobile = true;
          window.location.href =
            "covermyworkapp://" +
            environment.WEB_URL +
            "/recovery-password?AccessToken=" +
            this.accessToken +
            "&IsChangePassword=" +
            this.IsChangePassword;
        }
      }
    });
    this.initializeChangePasswordForm();
    this.initializeMessages();
    this.utilityService.hideLoading();
  }

  initializeChangePasswordForm() {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: new FormControl("", null),
        newPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(16),
        ]),
        confirmPassword: new FormControl("", [Validators.required]),
        userName: new FormControl(localStorage.getItem(Constants.USERNAME)),
        isChangePassword: new FormControl(this.IsChangePassword),
        accessToken: new FormControl(encodeURIComponent(this.accessToken)),
        companyId: new FormControl(
          Number(localStorage.getItem(Constants.COMPANYID))
        ),
      },
      {
        validators: this.validationService.confirmedValidator(
          "newPassword",
          "confirmPassword"
        ),
      }
    );
  }

  initializeMessages() {
    this.messageList.currentPassword = {
      required:
        Constants.VALIDATION_MSG.CHANGEPASSWORD.CURRENT_PASSWORD_REQUIRED,
    };
    this.messageList.newPassword = {
      required: Constants.VALIDATION_MSG.CHANGEPASSWORD.NEW_PASSWORD_REQUIRED,
    };
    this.messageList.confirmPassword = {
      required:
        Constants.VALIDATION_MSG.CHANGEPASSWORD.CONFIRM_PASSWORD_REQUIRED,
      confirmedValidator:
        Constants.VALIDATION_MSG.CHANGEPASSWORD
          .PASSWORD_CONFIRM_PASSWORD_NOT_MATCH,
    };
  }

  toggleisShowNewPassword() {
    this.isShowNewPassword = !this.isShowNewPassword;
  }

  toggleisShowConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  onSubmit() {
    this.formService.markFormGroupTouched(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordService
      .updatePasswordByMail(this.changePasswordForm.value)
      .then(
        (res) => {
          if (res["Success"]) {
            this.toaster.success(Constants.PASSWORD_UPDATE_SUCCESS_MSG);
            this.router.navigate([""]);
          } else {
            this.toaster.error(res["Message"]);
          }
          //this.router.navigate([defaultRoutes[Number(localStorage.getItem(Constants.ROLE))]]);
        },
        (err) => {}
      );
  }

  cancel() {
    this.router.navigate([""]);
  }
}
